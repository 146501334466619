<template>
  <div>
    <h1>activité du parcours clinique</h1>
    <el-row style="width: 820px" v-loading="parcourscliniqueLoadStatus == 1">
      <el-col :span="24">
        <form-parcoursclinique :ismeddetail="ismeddetail" ref="formPc">
        </form-parcoursclinique>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormParcoursclinique from "@/components/rh/ui/form/FormParcoursclinique";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-parcoursclinique": FormParcoursclinique },

  data() {
    return {
      loadCount: 0,
    };
  },
  props: {
    ismeddetail: Boolean,
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("pc/loadParcoursclinique", this.idPc);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idPc() {
      // récupère le id de la parcoursclinique dans l'url
      return String(this.$route.params.idpc);
    },
    parcourscliniqueLoadStatus() {
      return this.$store.state.pc.parcourscliniqueLoadStatus;
    },
    parcourscliniqueUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.pc.parcourscliniqueUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.parcourscliniqueLoadStatus == 2) {
        // charge les données de la thèse
        const pc = this.$store.state.pc.parcoursclinique;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.pcLoaded = JSON.stringify(pc);
      } else if (this.parcourscliniqueLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formPc.$refs.formParcoursclinique.validate((valid) => {
        if (valid) {
          let pc = this.$store.state.pc.parcoursclinique;

          // enlever les espaces avant et après dans les string
          trimStringInObj(pc);

          const pcInBD = this.$store.getters["pc/ParcourscliniqueInBD"];
          const id = this.idPc;
          const doublon =
            pcInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == pc.id_med &&
                m[2] == pc.id_activite &&
                m[3] == pc.debut_date
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modification et pour l'enregistrement
          const pcdataStr = JSON.stringify(pc);

          // vérifie si les données ont été modifiées
          if (localStorage.pcLoaded == pcdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si une parcoursclinique idendique existe déjà pour le médecin
          } else if (doublon) {
            this.$alert(
              "Cette activité avec cette même date de début est déjà enregistrée pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("pc/updateParcoursclinique", pc);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const pc = this.$store.state.pc.parcoursclinique;
      const pcStr = JSON.stringify(pc);
      if (localStorage.pcLoaded != pcStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.pcLoaded);
        this.$store.commit("pc/setParcoursclinique", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modification
      const pc = this.$store.state.pc.parcoursclinique;
      const pcStr = JSON.stringify(pc);

      if (localStorage.pcLoaded == pcStr) {
        // si pas de modifications
        // retourne à la liste des formations pré-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des fthèses
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    parcourscliniqueLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    parcourscliniqueUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.parcourscliniqueUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        const pc = this.$store.state.pc.parcoursclinique;
        localStorage.pcLoaded = JSON.stringify(pc);
      } else if (this.parcourscliniqueUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
