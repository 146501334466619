<template>
  <el-main class="rh-form">
    <form-parcourscliniqueedit :routetolist="{ name: 'parcourscliniques' }" />
  </el-main>
</template>
<script>
import FormParcourscliniqueedit from "@/components/rh/formulaires/FormParcourscliniqueedit";

export default {
  components: { "form-parcourscliniqueedit": FormParcourscliniqueedit },
};
</script>
